import get from 'lodash.get'
import { store } from '../store/index'

/*
 * Remove all but items from replies
 */
const onlyItems = k => !/(__qtype|_comment|_label|skipped)$/.test(k)

/*
 * Checks the activeness of a question when forItem parameter is not
 * specified or of an item when forItem is provided (e.g.: _comment)
 */
export const isActive = (tks, forItem = '') => {
  let state = store.getState()
  let toggleables = get(state.toggleables, tks)
  let replies = get(state.data, tks)

  if ('_rowcomment' in replies && replies['_rowcomment'] === '') return false

  if (toggleables['questionStatus'] === false) {
    return false
  } else {
    if (forItem !== '') return toggleables['repliesStatus'][forItem]

    let commonKeys = []
    if (
      ['dragndrop', 'ranking'].indexOf(replies['__qtype']) !== -1 ||
      (replies['__qtype'] === 'radiolist' && typeof replies['_rep'] !== 'undefined')
    ) {
      commonKeys = Object.keys(toggleables['repliesStatus']).filter(onlyItems)
    } else {
      commonKeys = Object.keys(replies).filter(onlyItems)
    }
    for (let item of commonKeys) {
      //TODO: removed _rep on radiolist toggleables
      if (toggleables['repliesStatus'][item]) return true
    }
  }
  return false
}

export const isSkipped = tks => {
  let state = store.getState()
  let replies = get(state.data, tks)

  // case question skipped (non matrices' children)
  if (get(replies, 'skipped', 0) === 1) return true

  if (/_row_/.test(tks[1])) {
    // check if parent question is skipped
    // matrix, mmatrix, mrating
    let parentQname = tks[1].split('_row_')[0]
    if (get(state.data, [tks[0], parentQname, 'skipped'], 0) === 1) return true
  }

  return false
}

/*
 * Checks if a question is answered when forItem parameter is not
 * specified, or if an item is answered when forItem is provided
 */
export const isAnswered = (tks, forItem = '') => {
  let state = store.getState()
  let toggleables = get(state.toggleables, tks)
  let replies = get(state.data, tks)
  let itemsGroups = get(state.internalState.uniqueGroups, tks)
  let qtype = replies['__qtype']
  let commonKeys = Object.keys(replies).filter(onlyItems)

  if (forItem !== '') {
    if (/_comment|_rowcomment/.test(forItem)) {
      let status = replies[forItem].trim() !== ''
      return { status, errored: status ? [] : [`${tks[0]}.${tks[1]}.${forItem}`] }
    } else if (['number', 'rating', 'select', 'date', 'dragndrop', 'ranking', 'search'].includes(qtype)) {
      return { status: replies[forItem] !== null, errored: [] }
    } else if (qtype === 'checkboxlist' || forItem === 'skipped') {
      return { status: replies[forItem] === 1, errored: [] }
    } else {
      //TEXT, MEMO, TIME
      return { status: replies[forItem].trim() !== '', errored: [] }
    }
  }

  if (isSkipped(tks)) return { status: true, errored: [] }
  if (qtype === 'radiolist' && typeof replies['_rep'] === 'undefined') qtype = 'mselectchild' // IS MSELECT

  switch (qtype) {
    case 'mselectchild': {
      let valid = true
      let errored = []
      for (let k of commonKeys) {
        let keyValid = toggleables['repliesStatus'][k] === false || replies[k] !== null
        valid = valid && keyValid && toggleables['questionStatus']
        if (!keyValid) {
          let rg = new RegExp(`^${k}.:.*`, 'i')
          Object.keys(toggleables['repliesStatus']).forEach(tk => {
            if (rg.test(tk)) {
              errored.push([tks[0], tks[1], tk].join('.'))
              errored.push([tks[0], tks[1], tk.split('.')[0]].join('.'))
            }
          })
        }
      }
      return { status: valid, errored }
    }

    case 'radiolist': {
      let errored = []
      let active = 0
      Object.keys(toggleables['repliesStatus'])
        .filter(k => !/_comment/.test(k))
        .forEach(el => {
          if (toggleables['repliesStatus'][el]) {
            active++
            errored.push([tks[0], tks[1], el].join('.'))
          }
        })
      if (active === 0) return { status: toggleables['questionStatus'], errored: [] }
      let status = replies['_rep'] !== null && toggleables['questionStatus']
      return { status, errored: status ? [] : errored }
    }

    case 'select':
    case 'text':
    case 'memo':
    case 'number':
    case 'date':
    case 'time':
    case 'search':
    case 'rating': {
      let inactiveCount = 0
      let errored = []
      let status = itemsGroups.reduce((acc, group) => {
        let answered = 0
        let active = 0
        let noval = ['text', 'memo', 'time'].includes(qtype) ? '' : null
        for (let item of group) {
          if (!toggleables['repliesStatus'][item]) {
            inactiveCount++
          } else {
            let reply = typeof replies[item] === 'string' ? replies[item].trim() : replies[item]
            if (reply !== noval) {
              answered++
            } else {
              errored.push([tks[0], tks[1], item].join('.'))
            }
            active++
          }
        }
        return (active > 0 && active === answered) || acc
      }, false)
      let ret = (inactiveCount === commonKeys.length || status) && toggleables['questionStatus']
      return { status: ret, errored }
    }

    case 'checkboxlist': {
      let inactive = 0
      let checked = 0
      let errored = []
      for (let item of commonKeys) {
        if (toggleables['repliesStatus'][item]) {
          if (replies[item] === 1) {
            checked++
          } else {
            errored.push([tks[0], tks[1], item].join('.'))
          }
        } else {
          inactive++
        }
      }
      let ret = (inactive === commonKeys.length || checked > 0) && toggleables['questionStatus']
      return { status: ret, errored }
    }

    case 'dragndrop':
    case 'ranking': {
      let options = state.internalState.pages
        .find(page => page.name === tks[0])
        .content.find(question => question.name === tks[1]).options

      // if min_reps is present required must validate on first answer
      let min =
        typeof get(options, ['min_reps', 'value']) !== 'undefined'
          ? 1
          : Object.keys(get(state.data, tks)).filter(onlyItems).length

      // if max_reps is present required must validate on first answer
      if (typeof get(options, ['max_reps', 'value']) !== 'undefined') min = 1

      let ret = matchRepsLimit(tks, min)
      return { ...ret, status: ret.status && toggleables['questionStatus'] }
    }

    case 'searchtable': {
      let status = commonKeys.reduce((acc, el) => replies[el] !== null && acc, true) && toggleables['questionStatus']
      return { status, errored: [...(status ? [] : [`${tks[0]}.${tks[1]}`])] }
    }

    case 'video': {
      let inactive = 0
      let checked = 0
      let errored = []
      for (let item of commonKeys) {
        if (toggleables['repliesStatus'][item]) {
          if (replies[item] === 1) {
            checked++
          } else {
            errored.push([tks[0], tks[1], item].join('.'))
          }
        } else {
          inactive++
        }
      }
      let ret = (inactive === commonKeys.length || checked > 0) && toggleables['questionStatus']
      return { status: ret, errored }
    }

    case 'matrix': // matrix subquestions are checked into radiolist case
    case 'mmatrix': // mmatrix subquestions are checked into checkboxlist case
    case 'mrating': // mrating subquestions are checked into rating case
    case 'mtext': // mtext subquestions are checked into text case
    case 'mranking': // mranking subquestions are checked into ranking case
    case 'mselect': // mselect subquestions are checked into mselectchild case
      break

    case 'info':
    case 'subsurvey':
    case 'geolocation':
      return { status: true, errored: [] }

    default:
      break
  }
  return { status: false, errored: [] }
}

/*
 * Checks if all number items are over minimum when forItem parameter is not
 * specified, or if an item is over lower bound when forItem is provided
 */
export const matchMinimum = (tks, minimum, forItem = '') => {
  let state = store.getState()
  let toggleables = get(state.toggleables, tks)
  let replies = get(state.data, tks)
  let ret = true
  let errored = []

  if (forItem !== '') {
    return { status: replies[forItem] >= minimum, errored: [] }
  }

  // if (get(replies, 'skipped', 0) !== 0) return true
  if (isSkipped(tks)) return { status: true, errored: [] }

  let commonKeys = Object.keys(replies).filter(onlyItems)
  for (let item of commonKeys) {
    if (toggleables['repliesStatus'][item] && replies[item] !== null && replies[item] < minimum) {
      ret = false
      errored.push([tks[0], tks[1], item].join('.'))
    }
  }
  return { status: ret, errored }
}

/*
 * Checks if all number items are under maximum when forItem parameter is not
 * specified, or if an item is under upper bound when forItem is provided
 */
export const matchMaximum = (tks, maximum, forItem = '') => {
  let state = store.getState()
  let toggleables = get(state.toggleables, tks)
  let replies = get(state.data, tks)
  let ret = true
  let errored = []

  if (forItem !== '') {
    return { status: replies[forItem] <= maximum, errored: [] }
  }

  if (isSkipped(tks)) return { status: true, errored: [] }

  let commonKeys = Object.keys(replies).filter(onlyItems)
  for (let item of commonKeys) {
    if (toggleables['repliesStatus'][item] && replies[item] > maximum) {
      ret = false
      errored.push([tks[0], tks[1], item].join('.'))
    }
  }
  return { status: ret, errored }
}

const roundNumber = (value, decimals) => {
  return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals)
}

/*
 * Checks if numeric sum is under provided value
 */
export const matchMaxSum = (tks, value) => {
  let state = store.getState()
  let replies = get(state.data, tks)
  let errored = []
  let sum = 0

  if (isSkipped(tks)) return { status: true, errored: [] }

  Object.keys(replies)
    .filter(onlyItems)
    .filter(r => r !== '_rowcomment')
    .forEach(repKey => {
      sum = sum + replies[repKey]
      errored.push([tks[0], tks[1], repKey].join('.'))
    })
  sum = roundNumber(sum, 9)
  let status = sum <= value
  return { status, errored: status ? [] : errored }
}
/*
 * Checks if numeric sum is over provided value
 */
export const matchMinSum = (tks, value) => {
  let state = store.getState()
  let replies = get(state.data, tks)
  let errored = []
  let sum = 0

  if (isSkipped(tks)) return { status: true, errored: [] }

  Object.keys(replies)
    .filter(onlyItems)
    .filter(r => r !== '_rowcomment')
    .forEach(repKey => {
      sum = sum + replies[repKey]
      errored.push([tks[0], tks[1], repKey].join('.'))
    })
  sum = roundNumber(sum, 9)
  let status = sum >= value
  return { status, errored: status ? [] : errored }
}
/*
 * Checks if numeric sum is equal to provided value
 */
export const matchExactSum = (tks, value) => {
  let state = store.getState()
  let replies = get(state.data, tks)
  let errored = []
  let sum = 0

  if (isSkipped(tks)) return { status: true, errored: [] }

  Object.keys(replies)
    .filter(onlyItems)
    .filter(r => r !== '_rowcomment')
    .forEach(repKey => {
      sum = sum + replies[repKey]
      errored.push([tks[0], tks[1], repKey].join('.'))
    })
  sum = roundNumber(sum, 9)
  let status = sum === value
  return { status, errored: status ? [] : errored }
}

/*
 * Checks if numeric sum is under provided value
 */
export const matchMaxChecked = (tks, value) => {
  let state = store.getState()
  let replies = get(state.data, tks)
  let errored = []
  let checked = 0

  if (isSkipped(tks)) return { status: true, errored: [] }

  Object.keys(replies)
    .filter(onlyItems)
    .forEach(repKey => {
      checked = checked + replies[repKey]
      if (replies[repKey] === 1) errored.push([tks[0], tks[1], repKey].join('.'))
    })
  let status = checked <= value
  return { status, errored: status ? [] : errored }
}
/*
 * Checks if numeric sum is over provided value
 */
export const matchMinChecked = (tks, value) => {
  let state = store.getState()
  let toggleables = get(state.toggleables, tks)
  let replies = get(state.data, tks)
  let errored = []
  let active = 0
  let checked = 0

  if (isSkipped(tks)) return { status: true, errored: [] }

  Object.keys(replies)
    .filter(onlyItems)
    .forEach(repKey => {
      if (replies[repKey] === 1) {
        checked++
      } else {
        errored.push([tks[0], tks[1], repKey].join('.'))
      }
      if (toggleables.repliesStatus[repKey] === true) active++
    })
  let status = checked >= Math.min(active, value)
  return { status, errored: status ? [] : errored }
}
/*
 * Checks if numeric sum is equal to provided value
 */
export const matchExactChecked = (tks, value) => {
  let state = store.getState()
  let toggleables = get(state.toggleables, tks)
  let replies = get(state.data, tks)
  let errored = []
  let active = 0
  let checked = 0

  if (isSkipped(tks)) return { status: true, errored: [] }

  Object.keys(replies)
    .filter(onlyItems)
    .forEach(repKey => {
      errored.push([tks[0], tks[1], repKey].join('.'))
      if (replies[repKey] === 1) checked++
      if (toggleables.repliesStatus[repKey] === true) active++
    })
  let status = checked === Math.min(active, value)
  return { status, errored: status ? [] : errored }
}

/*
 * Checks if all text items match an email format
 * Regexp taken from https://emailregex.com/
 */
const emailRegExp =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z0-9-]+.)+[a-zA-Z]{2,}))$/
export const matchEmail = tks => {
  let state = store.getState()
  let toggleables = get(state.toggleables, tks)
  let replies = get(state.data, tks)
  let errored = []
  let status = true

  if (isSkipped(tks)) return { status: true, errored: [] }

  let commonKeys = Object.keys(replies).filter(onlyItems)
  for (let item of commonKeys) {
    if (toggleables['repliesStatus'][item] && !emailRegExp.test(replies[item])) {
      status = false
      errored.push([tks[0], tks[1], item].join('.'))
    }
  }

  return { status, errored: status ? [] : errored }
}

/*
 * Checks if all text items match an URL format
 */
const urlRegExp =
  /^(http:\/\/|https:\/\/|ftp:\/\/|ftps:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?([/?].*)?$/i
export const matchUrl = tks => {
  let state = store.getState()
  let toggleables = get(state.toggleables, tks)
  let replies = get(state.data, tks)
  let errored = []
  let status = true

  if (isSkipped(tks)) return { status: true, errored: [] }

  let commonKeys = Object.keys(replies).filter(onlyItems)
  for (let item of commonKeys) {
    if (toggleables['repliesStatus'][item] && !urlRegExp.test(replies[item])) {
      status = false
      errored.push([tks[0], tks[1], item].join('.'))
    }
  }

  return { status, errored: status ? [] : errored }
}

/*
 * Checks if all text items match a RegExp
 */
export const matchPattern = (tks, pattern, flags) => {
  let state = store.getState()
  let toggleables = get(state.toggleables, tks)
  let replies = get(state.data, tks)
  let errored = []
  let status = true

  if (isSkipped(tks)) return { status: true, errored: [] }

  const regexp = new RegExp(pattern, flags)
  let commonKeys = Object.keys(replies).filter(onlyItems)
  for (let item of commonKeys) {
    if (toggleables['repliesStatus'][item] && !regexp.test(replies[item])) {
      status = false
      errored.push([tks[0], tks[1], item].join('.'))
    }
  }

  return { status, errored: status ? [] : errored }
}

/*
 * Dragndrop Limit check
 */
export const matchRepsLimit = (tks, limit) => {
  let state = store.getState()
  let toggleables = get(state.toggleables, tks)
  let dataSlice = get(state.data, tks)
  let errored = []
  let checked = 0
  let active = 0

  if (isSkipped(tks)) return { status: true, errored: [] }

  let replies = Object.keys(dataSlice).filter(onlyItems)

  checked = replies.filter(repKey => dataSlice[repKey] !== null).length
  active = Object.keys(toggleables['repliesStatus']).filter(key => toggleables['repliesStatus'][key] === true).length

  let customLimit = active >= limit ? limit : active

  let isOrdered = true
  for (let i = 1; i <= checked; i++) {
    isOrdered = isOrdered && dataSlice[i.toString()] !== null //check replies ordering
  }

  let status = checked >= customLimit && isOrdered
  if (!status) errored.push([tks[0], tks[1]].join('.'))
  return { status, errored: status ? [] : errored }
}

/* 
  export const matchWatchLimit = (tks: Array<string>, limit: number): ValidatorReturnObject => {
  let state = store.getState()
  let toggleables = get(state.toggleables, tks)
  let replies = get(state.data, tks)
  let errored = []
  let active = 0

  if (isSkipped(tks)) return { status: true, errored: [] }

  active = toggleables['repliesStatus']['_rep'] === true
  
  let status = active ? replies['_rep'] === "1" : true

  if (!status) errored.push('_rep')
  return { status, errored: status ? [] : errored }
}
  */

/*
 * Returns false if at least one question in the page has INVALID status
 */
export const isPageValid = pageName => {
  let questionStatuses = store.getState().validators.questionStatuses[pageName]
  for (let q in questionStatuses) {
    if (questionStatuses[q] === 'INVALID') {
      return false
    }
  }
  return true
}

export const isFirstInvalidQuestion = (pageName, questionName) => {
  let questionStatuses = store.getState().validators.questionStatuses[pageName]
  let first
  for (let q in questionStatuses) {
    if (questionStatuses[q] === 'INVALID') {
      first = q
      break
    }
  }
  if (first === questionName) return true
  return false
}
